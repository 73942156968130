<template>
    <div class="engine-group-component">
        <template v-for="(engine, index) in engines">
            <template v-if="index < max">
                <el-tooltip :content="engine.name" placement="top" :key="index">
                    <div class="item" :style="itemStyle(index)">
                        <svg-icon :name="`ie-${engine.icon}`"></svg-icon>
                    </div>
                </el-tooltip>
            </template>
        </template>
        <template v-if="engines.length > max">
            <div class="item" :style="itemStyle(max)">{{engines.length - max}}</div>
        </template>
    </div>
</template>
<script>
export default {
    name: 'EngineGroup',
    props: {
        list: {
            type: Array,
            default: [],
        },
        size: {
            type: Number,
            default: 20,
        },
        max: {
            type: Number,
            default: 2,
        },
    },
    data () {
        return {
            engines: [],
        }
    },
    methods: {
        itemStyle (index){
            let styles = {
                'width': `${this.size}px`,
                'height': `${this.size}px`,
                'font-size': index === this.max ? (this.size - 10 > 16 ? `16px` : `12px`) : `${this.size - 10 < 12 ? 12 : this.size - 10}px`,
                'z-index': index + 1,
            };
            return styles;
        },
        initEngines () {
            let map = new Map();
            for (let item of this.list) {
                if(!map.has(item.key)) {
                    map.set(item.key, item);
                }
            }
            this.engines = [...map.values()];
        },
    },
    mounted () {
        this.initEngines();
    },
    watch: {
        "list" (newVal, oldVal) {
            this.initEngines();
        },
    }
    
}
</script>
<style lang="scss" scoped>
.engine-group-component {
    display: flex;
    .item {
        box-sizing: border-box;
        // border: 1px solid #0000001A;
        border-radius: 50px;
        // background-color: #fff;
        margin-left: -5px;
        display: flex;
        align-items: center;
        justify-content: center;
        &:first-child {
            margin-left: initial;
        }
    }
}
</style>

import { render, staticRenderFns } from "./LogoLoading.vue?vue&type=template&id=5da139a4&scoped=true"
var script = {}
import style0 from "./LogoLoading.vue?vue&type=style&index=0&id=5da139a4&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5da139a4",
  null
  
)

export default component.exports
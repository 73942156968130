<template>
    <div class="full-loading-component" v-if="show">
        <logo-loading></logo-loading>
        <div class="title">{{title}}</div>
        <div class="subtitle">{{subtitle}}</div>
    </div>
</template>
<script>
import LogoLoading from '@/components/LogoLoading';
export default {
    components:{LogoLoading},
    props:{
        title:{
            type:String,
            default: function(){
                return this.$t('component.FullLoading_title')
            }
        },
        subtitle:{
            type:String,
            default:''
        }
    },
    data(){
        return{
            show:false
        }
    },
    methods:{
        setShow(){
            this.show = true;
        },
        setHide(){
            this.show = false;
        }

    }
}
</script>
<style lang="scss" scoped>
.full-loading-component{
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1000;
    background-color: #ffffffE6;
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    > .title{
        font-size: 16px;
        color: #000000;
        margin-top: 20px;
    }
    > .subtitle{
        font-size: 12px;
        color: #000000CC;
        margin-top: 10px;
    }
}
</style>

<template>
    <svg :width="size*3" :height="size" viewBox="0 0 36 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="36" height="12" rx="6" fill="#FFF2D1"/>
        <path d="M17.4841 6.62968H15.6846V6H17.4841C17.8326 6 18.1147 5.95187 18.3306 5.85562C18.5465 5.75936 18.7038 5.62567 18.8024 5.45455C18.9042 5.28342 18.9551 5.08824 18.9551 4.86898C18.9551 4.66845 18.9042 4.47995 18.8024 4.30348C18.7038 4.12701 18.5465 3.98529 18.3306 3.87834C18.1147 3.76872 17.8326 3.7139 17.4841 3.7139H15.8928V8.91979H15V3.08021H17.4841C17.9929 3.08021 18.4231 3.15642 18.7747 3.30882C19.1263 3.46123 19.393 3.67246 19.575 3.94251C19.7569 4.20989 19.8479 4.51604 19.8479 4.86096C19.8479 5.23529 19.7569 5.55481 19.575 5.81952C19.393 6.08422 19.1263 6.2861 18.7747 6.42513C18.4231 6.5615 17.9929 6.62968 17.4841 6.62968Z" fill="#333333"/>
        <path d="M20.9766 3.08021H23.2063C23.712 3.08021 24.1391 3.14706 24.4876 3.28075C24.8392 3.41444 25.1059 3.6123 25.2879 3.87433C25.4729 4.13369 25.5654 4.45321 25.5654 4.83289C25.5654 5.10027 25.5022 5.34492 25.3758 5.56684C25.2524 5.7861 25.0736 5.97326 24.8392 6.12834C24.6079 6.28075 24.3303 6.39439 24.0065 6.46925L23.7567 6.55348H21.6612L21.652 5.9238H23.234C23.5547 5.9238 23.8215 5.87567 24.0343 5.77941C24.2471 5.68048 24.4074 5.54813 24.5154 5.38235C24.6233 5.21658 24.6773 5.03342 24.6773 4.83289C24.6773 4.60829 24.6264 4.41176 24.5246 4.24332C24.4229 4.07487 24.2625 3.94519 24.0435 3.85428C23.8277 3.7607 23.5486 3.7139 23.2063 3.7139H21.8694V8.91979H20.9766V3.08021ZM24.9132 8.91979L23.2756 6.27273L24.2054 6.26872L25.8661 8.87166V8.91979H24.9132Z" fill="#333333"/>
        <path d="M32 5.81551V6.18449C32 6.62299 31.9368 7.01604 31.8103 7.36364C31.6839 7.71123 31.502 8.00668 31.2645 8.25C31.027 8.49332 30.7418 8.67914 30.4087 8.80749C30.0787 8.93583 29.7087 9 29.2985 9C28.9007 9 28.5352 8.93583 28.2022 8.80749C27.8722 8.67914 27.5854 8.49332 27.3418 8.25C27.1012 8.00668 26.9146 7.71123 26.782 7.36364C26.6494 7.01604 26.5831 6.62299 26.5831 6.18449V5.81551C26.5831 5.37701 26.6479 4.98529 26.7774 4.64037C26.91 4.29278 27.0966 3.99733 27.3371 3.75401C27.5777 3.50802 27.8629 3.32086 28.1929 3.19251C28.526 3.06417 28.8914 3 29.2893 3C29.6994 3 30.0695 3.06417 30.3995 3.19251C30.7325 3.32086 31.0178 3.50802 31.2552 3.75401C31.4958 3.99733 31.6793 4.29278 31.8057 4.64037C31.9352 4.98529 32 5.37701 32 5.81551ZM31.1165 6.18449V5.80749C31.1165 5.45989 31.0748 5.15241 30.9916 4.88503C30.9114 4.61765 30.7927 4.39305 30.6354 4.21123C30.4781 4.02941 30.2854 3.89171 30.0571 3.79813C29.832 3.70455 29.5761 3.65775 29.2893 3.65775C29.0117 3.65775 28.7604 3.70455 28.5352 3.79813C28.3132 3.89171 28.122 4.02941 27.9616 4.21123C27.8044 4.39305 27.6825 4.61765 27.5962 4.88503C27.5098 5.15241 27.4667 5.45989 27.4667 5.80749V6.18449C27.4667 6.53476 27.5098 6.84492 27.5962 7.11497C27.6825 7.38235 27.8059 7.60829 27.9663 7.79278C28.1297 7.9746 28.3224 8.1123 28.5445 8.20588C28.7696 8.29947 29.021 8.34626 29.2985 8.34626C29.5884 8.34626 29.8459 8.29947 30.071 8.20588C30.2961 8.1123 30.4858 7.9746 30.64 7.79278C30.7973 7.60829 30.916 7.38235 30.9962 7.11497C31.0764 6.84492 31.1165 6.53476 31.1165 6.18449Z" fill="#333333"/>
        <path d="M6.14122 3.23336C6.26173 3.08568 6.44223 3 6.63284 3H10.599C10.7896 3 10.9701 3.08568 11.0906 3.23336L12.0889 4.45678C12.2825 4.694 12.2791 5.03561 12.0808 5.26889L9.09939 8.77642C8.846 9.07452 8.38584 9.07453 8.13245 8.77643L5.15105 5.26889C4.95276 5.03561 4.94934 4.694 5.1429 4.45678L6.14122 3.23336Z" fill="#F3B947"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M10.1623 5.1512C10.2962 5.26422 10.3131 5.46439 10.2001 5.59828L9.36037 6.59303C8.98517 7.03752 8.30254 7.04445 7.91839 6.60767L7.03491 5.60316C6.91919 5.47159 6.93204 5.27112 7.06362 5.1554C7.19519 5.03968 7.39565 5.05254 7.51137 5.18411L8.39485 6.18862C8.5229 6.33421 8.75044 6.3319 8.87551 6.18374L9.71522 5.18898C9.82824 5.05509 10.0284 5.03818 10.1623 5.1512Z" fill="#FDF8FC"/>
    </svg>
</template>
<script>
export default {
    name: "ProIcon",
    props: {
        size: {
            type: Number,
            default: 12,
        },
    },
};
</script>
